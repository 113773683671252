// DEFAULT FONT SIZE - for toRem function */
$defaultFontSize: 16;

// COLORS */
$white:           #fff;
$black:           #000;
$darkGrey:        #363636;
$grey:            #8d8d8d;
$lightGrey:       #d9d9d9;
$blue:            #0a72ba;
$skyBlue:         #008cd0;
$lightBlue:       #6dcff6;
$superLightBlue:  #73c0e5;
$purple:          #532c8c;
$brickRed:        #8c3f1a;
//services colors
$vyvoj: #abd373;
$test: #1cbcb4;
$vzdelavani: #7da7d9;
$servis: #bd8dbf;
$bodyshop: #f26c4f;
$provereni: #fbae5c;

// MEDIA QUERIES */
$maxW-1200: 'only screen and (max-width: 1200px)';
$maxW-1024: 'only screen and (max-width: 1024px)';
$maxW-960:  'only screen and (max-width: 960px)';
$maxW-750:  'only screen and (max-width: 750px)';
$maxW-650:  'only screen and (max-width: 650px)';
$maxW-460:  'only screen and (max-width: 460px)';
$maxW-320:  'only screen and (max-width: 320px)';

// GPU ACCELERATION HACK (note: 3d transforms are extremely laggy on mobile versions of FF -> bug)
$gpuAccelerate: translate3d(0, 0, 0);
