.previewBox {
  text-align: left;
  &__ttl {
    margin: 20px 0 15px;
    font-size: 20px
  }
  &__desc {
    
  }
}
