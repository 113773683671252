.header {
  padding-bottom: 180px;
  position: relative;
  background-size: 100% 70px;
  text-align: center;
  overflow: hidden;
  @media #{$maxW-750} {
    padding-bottom: 75px;
  }
  &--normalSize {
    min-height: auto;
  }
  &__fixed {
    width: 100%;
    padding-top: 57px;
    position: relative;
    &::before {
      @include size(100%, 12px);
      content: '';
      display: block;
      position: absolute;
      top: 0;
      background-color: $blue;
      @media #{$maxW-960} {
        height: 7px;
      }
      @media #{$maxW-750} {
        height: 6px;
      }
    }
  }
  &__title {
    margin-top: 10px;
    @media #{$maxW-750} {
      margin-top: 80px;
    }
  }
  &__shape {
    @include size(auto, 45px);
    position: absolute;
    top: 11px;
    left: 50%;
    transform: translateX(-50%);
    @media #{$maxW-960} {
      top: 6px;
    }
    @media #{$maxW-960} {
      top: 5px;
    }
  }
  &__logo {
    width: 400px;
    margin-top: 15px;
    @media #{$maxW-960} {
      @include size(auto, 40px);
    }
    @media #{$maxW-750} {
      height: 25px;
      margin-top: 10px;
    }
  }
  &__parallax {
    min-height: 58vh;
    z-index: 1;
    @media #{$maxW-750} {
      width: 670px;
      max-width: 100%;
    }
    &Repl {
      display: none;
      @media #{$maxW-750} {
        display: block;
      }
    }
    &Bg {
      z-index: 2;
      transform: rotate3d(1, 0, 0 , -90deg);
      @media #{$maxW-750} {
        display: none;
      }
    }
  }
  &__helperGrid {
    min-height: 58vh;
  }
  &__anim {
    position: relative;
  }
  &__px {
    width: auto;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0;
    overflow: hidden;
    @media #{$maxW-750} {
      display: none;
    }
    // persons
    &--1 {
      width: 7%;
      // height: 215px;
      top: 15%;
      left: 39%;
      z-index: 3;
    }
    &--2 {
      width: 7%;
      // height: 255px;
      top: 11.6%;
      left: 58%;
      z-index: 3;
    }
    // arrows
    &--3 {
      @include size(16.5%, 17%);
      top: 41%;
      left: 25.7%;
      background-image: url('../content/images/header/sipka_1.svg');
      background-position: 157px 65px;
      opacity: 1;
      z-index: 2;
    }
    &--4 {
      @include size(6.5%, 20%);
      top: 74%;
      left: 39.3%;
      background-image: url('../content/images/header/sipka_2.svg');
      background-position: 0 -74px;
      opacity: 1;
      z-index: 2;
    }
    &--5 {
      @include size(17%, 20%);
      top: 18.5%;
      left: 61.2%;
      background-image: url('../content/images/header/sipka_3.svg');
      background-position: -160px 75px;
      opacity: 1;
      z-index: 2;
    }
    // devices
    &--6 {
      width: 25%;
      // height: 145px;
      top: 15.9%;
      left: 9%;
      z-index: 5;
    }
    &--7 {
      width: 34%;
      // height: 195px;
      top: 53%;
      left: 41.3%;
      z-index: 5;
    }
    &--8 {
      width: 12%;
      // height: 190px;
      top: 6.6%;
      left: 78.4%;
      z-index: 5;
    }
  }
}

// PARALLAX EFFECT
.header__parallax.parallax {
  .header {
    &__parallaxBg {
      transform: rotate3d(1, 0, 0 , 0deg);
      transform-origin: bottom;
      transition: transform .3s linear;
      transition-delay: .3s;
    }
    // fix for scroll delay
    &__parallaxBg.fix {
      transition: none;
      transition-delay: 0s;
    }
    &__px {
      // persons
      &--1 {
        opacity: 1;
        transition: opacity .3s linear;
        transition-delay: .8s;
      }
      &--2 {
        opacity: 1;
        transition: opacity .3s linear;
        transition-delay: 1s;
      }
      // arrows
      &--3 {
        background-position: 0 0;
        transition: background-position .1s linear;
        transition-delay: 1.2s;
      }
      &--4 {
        background-position: 0 0;
        transition: background-position .1s linear;
        transition-delay: 1.4s;
      }
      &--5 {
        background-position: 0 0;
        transition: background-position .1s linear;
        transition-delay: 1.6s;
      }
      // devices
      &--6 {
        opacity: 1;
        transition: opacity .2s linear;
        transition-delay: 1.3s;
      }
      &--7 {
        opacity: 1;
        transition: opacity .2s linear;
        transition-delay: 1.5s;
      }
      &--8 {
        opacity: 1;
        transition: opacity .2s linear;
        transition-delay: 1.7s;
      }
    }
  }
}
