// TEXT */
.u-bold {
  font-weight: bold;
}

.u-decor-ul {
  text-decoration: underline;
}

.u-light {
  @include robotoLight;
}

.u-regular {
  @include robotoRegular;
}

.u-bold {
  @include robotoBold;
}

.u-nowrap {
  white-space: nowrap;
}

// STRUCTURE */
.u-sectionHeader {
  margin-top: 65px;
  display: inline-block;
}

.u-breaker {
  @media #{$maxW-750} {
    margin-top: 25px;
    display: block;
  }
}

// COLORS */
//text
.u-clrWhite {
  color: $white;
}

.u-clrGrey {
  color: $grey;
}

.u-clrBlu {
  color: $blue;
}

.u-clrLightBlu {
  color: $lightBlue;
}

//bg
.u-bg--grey {
  background-color: $grey;
}

.u-bg--lightGrey {
  background-color: $lightGrey;
}

.u-bg--blu {
  background-color: $blue;
}

.u-bg--skyBlu {
  background-color: $skyBlue;
}

.u-bg--superLightBlu {
  background-color: $superLightBlue;
}

// POSITIONING
.u-posRel {
  position: relative;
}

.u-tac {
  text-align: center;
}

.u-tal {
  text-align: left;
}

.u-flr {
  float: right;
}

.u-fll {
  float: left;
}

// STATE
.hide {
  display: none !important;
}

.show {
  display: block !important;
}


// VERTICAL CENTER
.ghost {
  min-height: 100vh;
  margin: 0 -2.5px;
  display: inline-block;
  vertical-align: middle;
}
.vAlign {
  display: inline-block;
  vertical-align: middle;
}
