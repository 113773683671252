@mixin makeBtn($bg, $hover: $white) {
  border: 1px solid $bg;
  background-color: $bg;
  color: $hover;
  transition: color .3s linear, background-color .3s linear, border .3s linear;
  &:hover {
    background-color: $hover;
    color: $bg;
  }
}

.btn {
  @include robotoRegular;
  margin-top: 10px;
  padding: 10px 15px;
  display: inline-block;
  font-size: 18px;
  white-space: nowrap;
  &--blu {
    @include makeBtn($skyBlue);
  }
  &--arrow {
    position: relative;
    &::before,
    &::after {
      @include size(20px, 20px);
      content: '';
      display: inline-block;
      transition: opacity .3s linear;
    }
    &::before {
      margin-right: 15px;
      vertical-align: middle;
      background: url('../content/icons/arrow_w_l.png') no-repeat center;
      background-size: contain;
      opacity: 1;
    }
    &:hover::before {
      opacity: 0;
    }
    &::after {
      position: absolute;
      bottom: 10px;
      left: 15px;
      background: url('../content/icons/arrow_b_l.png') no-repeat center;
      background-size: contain;
      opacity: 0;
    }
    &:hover::after {
      opacity: 1;
    }
  }
  &--shadow {
    box-shadow: 0 46px 16px -20px #000, 0 12px 11px -3px rgba(0,0,0,0.1);
  }
}

.returnr {
  @include robotoBold;
  margin-top: 40px;
  display: block;
  font-size: 18px;
  color: $black;
}
