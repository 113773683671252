.footer {
  margin-top: -240px;
  padding-bottom: 40px;
  font-size: 14px;
  line-height: 23.5px;
  position: relative;
  z-index: 2;
  @media #{$maxW-750} {
    padding-bottom: 0;
  }
  &--default {
    margin: 0;
    padding: 0;
  }
  &__wrapper {
    width: 960px;
    max-width: 100%;
    margin: auto;
    border: 1px solid $lightGrey;
    box-sizing: content-box;
    @media #{$maxW-750} {
      border: 0;
      border-top: 1px solid $grey;
    }
  }
  &__left {
    width: 420px;
    max-width: 100%;
    @media #{$maxW-750} {
      width: 100%;
    }
  }
  &__right {
    width: 540px;
    max-width: 100%;
    display: flex;
    flex-wrap: nowrap;
    background-image: url('../content/images/map.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    background-color: $darkGrey;
    color: $white;
    @media #{$maxW-750} {
      width: 100%;
      flex-wrap: wrap;
    }
  }
  &__left,
  &__right {
    padding: 40px 35px;
    @media #{$maxW-750} {
      padding: 35px 35px;
    }
  }
  &__info {
    &:first-child {
      padding-right: 45px;
    }
  }
  &__ctcInfo {
    @include robotoBold;
    width: 70px;
    clear: both;
    float: left;
  }
  &__ctcDesc {
    float: left;
    a {
      color: $white;
    }
  }
}
