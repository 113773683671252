.grid {
  @include flex();
  // margin: 0 -15px 0;
  &--spaced {
    justify-content: space-between;
    @media #{$maxW-750} {
      justify-content: center;
    }
  }
  &--wGap {
    margin: -15px;
  }
  &--alignL {
    justify-content: flex-start;
  }
  &--vCenter {
    align-items: center;
  }
}

.container {
  width: 960px;
  max-width: 85%;
  margin: auto;
  @media #{$maxW-960} {
    justify-content: center;
  }
  &--m {
    @media #{$maxW-960} {
      width: 800px;
      max-width: 90%;
      margin: auto;
    }
  }
}

.col {
  padding: 15px;
}

@include columns('');

@media #{$maxW-1024} {
  @include columns('-l');
}

@media #{$maxW-960} {
  @include columns('-m');
}

@media #{$maxW-750} {
  @include columns('-ms');
}

@media #{$maxW-460} {
  @include columns('-s');
}

@media #{$maxW-320} {
  @include columns('-xs');
}
