// STRUCTURE */
* {
  box-sizing: border-box;
  // REDUCE FIREFOX LAGS
  -moz-transform-style: flat !important;
}

html {
  font-size: 16px;
}

body {
  @include robotoLight;
  line-height: normal;
  overflow-x: hidden;
  font-family: 'Roboto Condensed', sans-serif;
}

section {
  position: relative;
}

header,
section {
  @media only screen and (min-width: 960px) {
    min-height: 100vh;
  }
}

img {
  @include maxSize(100%, 100%);
}

// TEXT */
h1 {
  font-size: toRem(52);
  @media #{$maxW-960} {
    font-size: toRem(34);
  }
}

h2 {
  font-size: toRem(48);
  @media #{$maxW-960} {
    font-size: toRem(30);
  }
}

h3 {
  font-size: toRem(44);
  @media #{$maxW-960} {
    font-size: toRem(26);
  }
}

h4 {
  font-size: toRem(40);
  @media #{$maxW-960} {
    font-size: toRem(22);
  }
}

h5 {
  font-size: toRem(35);
  @media #{$maxW-960} {
    font-size: toRem(18);
  }
}

h6 {
  font-size: toRem(30);
  @media #{$maxW-960} {
    font-size: toRem(14);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include robotoBold;
}

a {
  text-decoration: none;
}

a.default:hover {
  text-decoration: underline;
}

pre {
  white-space: pre-wrap;
}

b {
  @include robotoBold;
}

.sectionDesc {
  font-size: 24px;
  @media #{$maxW-960} {
    font-size: 18px;
  }
}

//  CUSTOM LIST
.customList {
  li {
    position: relative;
    padding-left: 13px;
    line-height: 2;
    &::before {
      @include size(7px, 7px);
      content: '';
      display: inline-block;
      position: absolute;
      top: calc(2rem / 2 - 3.5px);
      left: 0;
      border-radius: 100%;
      background-color: $purple;
    }
  }
  &--spaced {
    margin: 35px 0 30px;
  }
  &--red {
    li::before {
      background-color: $brickRed;
    }
  }
}

// HINT
.hint {
  display: inline-block;
  position: absolute;
  bottom: 65px;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 18px;
  color: $blue;
  z-index: 5;
  @media #{$maxW-960} {
    font-size: 14px;
  }
  @media #{$maxW-750} {
    display: none;
  }
}
