// INTEGROMAT SCREEN BLINK */

@keyframes blink {
  0%,
  5% {
    transform: rotate3d(1, 0, 0, -90deg);
    border-radius: 100%;
  }
  2% {
    transform: rotate3d(1, 0, 0, 0deg);
    border-radius: 80%;
  }
  90% {
    transform: rotate3d(1, 0, 0, -90deg);
    border-radius: 100%;
  }
  95% {
    transform: rotate3d(1, 0, 0, -60deg) scale3d(.2, .2, 1);
  }
  100% {
    transform: rotate3d(1, 0, 0, 0deg);
    border-radius: 100%;
  }
}

@keyframes spinMeRoundAround {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  50% {
    transform: translate3d(-50%, -50%, 0) rotate(-40deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(-40deg);
  }
}

@keyframes servicesLaptopBg {
  100% {
    background-image: url('../content/images/service/servis/ntb-b.svg');
  }
}

@keyframes servicesTableBg {
  100% {
    background-image: url('../content/images/service/vyvoj/table-b.svg');
  }
}
