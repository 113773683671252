.job {
  &.active {
    box-shadow: 0 0 307px -25px rgba(11, 84, 122, 0.75) inset;
    transition: box-shadow .4s linear;
  }
  &__slider {
    height: 100%;
    margin-right: 0;
    padding-bottom: 135px;
    @media #{$maxW-960} {
      padding-bottom: 50px;
    }
  }
  &__slide {
    padding-left: 30px;
    display: flex;
    justify-content: right;
    position: relative;
    @media #{$maxW-960} {
      flex-wrap: nowrap;
    }
    @media #{$maxW-750} {
      flex-wrap: wrap;
      justify-content: center;
    }
    @media #{$maxW-460} {
      padding: 0;
    }
  }
  // ARROWS
  &__arrows {
    display: none;
    @media #{$maxW-750} {
      top: 33%;
    }
    @media #{$maxW-460} {
      top: 28%;
    }
    @media #{$maxW-320} {
      top: 26%;
    }
    &--right,
    &--fake {
      right: -7%;
      @media #{$maxW-460} {
        right: -25px;
      }
    }
    &--left {
      left: -7%;
      @media #{$maxW-460} {
        left: -25px;
      }
    }
    &--fake {
      @media #{$maxW-750} {
        display: none;
      }
    }
    &.active {
      display: block;
    }
  }
  // PAGINATION
  &__pagination {
    position: relative;
    @media #{$maxW-460} {
      @include flex();
      justify-content: space-between;
    }
    &Wrapper {
      width: 220px;
      max-width: 25%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%) scale3d(1, 0, 1);
      z-index: 2;
      @media #{$maxW-960} {
        transform: translateY(-50%) !important;
      }
      @media #{$maxW-750} {
        width: 100%;
        max-width: 100%;
        position: static;
        transform: none !important;
      }
      // active pagination state
      &.active {
        position: absolute;
        .swiper-pagination-bullet {
          @media #{$maxW-750} {
            margin: 0;
            border: 0;
          }
          &:hover {
            @media #{$maxW-750} {
              text-decoration: none;
            }
          }
          svg {
            @media #{$maxW-750} {
              display: none;
            }
          }
        }
        .swiper-pagination-bullet-active {
          svg {
            fill: $white;
            opacity: 1;
          }
        }
        // fix for too large whitespace on small screens
        & + .job__wrapper .job__contentItem {
          @media #{$maxW-750} {
            height: auto;
            min-height: 800px;
            padding-top: 120px;
          }
          @media #{$maxW-460} {
            padding-top: 215px;
          }
        }
      } //- end active
    }
    .swiper-pagination-bullet {
      @include size(100%, auto);
      @include clearfix();
      border-top: 1px solid rgba(0, 0, 0, .15);
      border-radius: 0;
      background: none;
      line-height: 40px;
      text-align: left;
      opacity: 1;
      @media #{$maxW-750} {
        @include flexItem();
        width: 115px;
        margin: 0 10px;
        border: 0 !important;
      }
      &:last-child {
        border-bottom: 1px solid rgba(0, 0, 0, .15);
      }
      &:hover {
        text-decoration: underline;
      }
      .swiper-pagination-bullet-desc {
        @include robotoBold;
        display: inline-block;
        font-size: 18px;
        text-decoration: underline;
        @media #{$maxW-750} {
          font-size: 16px;
        }
        @media #{$maxW-750} {
          @include robotoLight;
          width: 100%;
          text-align: center;
          color: $black;
        }
      }
      svg {
        float: right;
        opacity: .15;
        transform: rotate(270deg) translateX(-50%);
        transition: opacity .4s linear, fill .4s linear;
        @media #{$maxW-750} {
          display: none;
        }
      }
      img {
        max-height: 125px;
        min-height: 125px;
        display: none;
        @media #{$maxW-750} {
          display: block;
        }
      }
    }
  }
  &__slideDesc {
    p {
      margin-top: 25px;
    }
  }
  // special rules for select
  .pagination__select {
    @media #{$maxW-960} {
      display: none;
    }
    @media #{$maxW-750} {
      display: block;
    }
  } //- end pagination
  // slide content
  &__slideLeft,
  &__slideRight {
    max-width: 525px;
  }
  &__slideLeft {
    text-align: left;
    @media #{$maxW-750} {
      order: 2;
    }
  }
  &__slideRight {
    padding-left: 15px;
    align-self: center;
    @media #{$maxW-750} {
      order: 1;
      padding: 0 0 40px;
    }
    img {
      @media #{$maxW-750} {
        max-height: 225px;
        min-height: 225px;
      }
    }
  }
  // select transform
  @include select(750px);
}
