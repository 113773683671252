// CREATE SELECT BOX
@mixin select($from) {
  .active.pagination {
    @media screen and (max-width: $from) {
      @include size(290px, auto);
      max-width: 90%;
      min-width: auto;
      padding: 0 10px 10px;
      display: none;
      position: absolute;
      top: 50px;
      bottom: auto;
      font-size: 14px;
      background: $white;
      color: $black;
      box-shadow: none;
      transform: none !important;
      transition: none;
    }
    .pagination__content {
      @media screen and (max-width: $from) {
        width: 100%;
        display: block;
        transform: none !important;
      }
    }
    .pagination__bullet {
      @media screen and (max-width: $from) {
        width: 100%;
        padding: 10px 10px;
        display: block;
        border-radius: 0;
        background: none;
        line-height: 1;
        transform: none !important;
        transition: background-color .2s linear;
        &:hover {
          background-color: $superLightBlue;
        }
        img {
          display: none;
        }
      }
    }
    .pagination__desc {
      @media screen and (max-width: $from) {
        width: 100%;
        display: block;
        position: static;
        text-align: left;
        text-decoration: none !important;
      }
    }
  }
  .pagination__select {
    display: none;
    @media screen and (max-width: $from) {
      @include size(290px, 40px);
      max-width: 90%;
      min-width: auto;
      padding: 0 10px;
      display: inline-block;
      position: absolute;
      top: 50px;
      bottom: auto;
      left: 50%;
      font-size: 18px;
      line-height: 40px;
      border: 1px solid $grey;
      background: rgba(255, 255, 255, .5);
      text-align: left;
      color: $black;
      white-space: nowrap;
      cursor: pointer;
      z-index: 3;
      transition: background-color .3s linear;
      &::before {
        @include dropdownArrow($skyBlue, bottom);
        content: '';
        display: inline-block;
        position: absolute;
        right: 15px;
      }
      br {
        display: none;
      }
    }
    @media #{$maxW-750} {
      left: 0;
      right: 0;
      margin: auto;
    }
    @media #{$maxW-460} {
      left: 0;
      top: 155px;
    }
    &.active {
      background-color: $white;
      &::before {
        @include dropdownArrow($skyBlue, top);
      }
    }
    &:hover {
      background-color: $white;
    }
  }
}

// GENERAL SETTINGS */
.swiper-button {
  &-prev {
    transform: rotate(-270deg);
    left: 60px;
    @media #{$maxW-960} {
      left: 35px;
    }
  }
  &-next,
  .job__arrows--fake {
    transform: rotate(270deg);
    right: 60px;
    @media #{$maxW-960} {
      right: 35px;
    }
  }
  &-prev,
  &-next,
  .job__arrows--fake {
    @include size(50px, 50px);
    background: url('../content/icons/arrow.svg');
    background-size: contain;
    opacity: .5;
    transition: opacity .3s linear;
    &:hover {
      opacity: 1;
    }
  }
  &-disabled {
    display: none !important;
  }
}

.swiper-slide h2 {
  @media #{$maxW-960} {
    font-size: 24px;
  }
}
