// THIS IS DEFAULT JOB VERSION WITHOUT SLIDER
.job {
  padding: 70px 0 110px;
  text-align: center;
  color: $white;
  position: relative;
  @media #{$maxW-960} {
    padding: 30px 0 100px;
  }
  &--detail {
    text-align: left;
    box-shadow: 0 0 307px -25px rgba(11, 84, 122, 0.75) inset;
  }
  &__wrapper {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
  }
  &__content {
    min-height: 65vh;
    margin-top: 55px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media #{$maxW-750} {
      display: none;
    }
  }
  &__detailImg {
    width: 200px;
    margin: auto;
    display: block;
  }
  //- ANIMATION HOLDER
  &__anim {
    @include size(calc(100% - 220px), 100%);
    position: relative;
    z-index: 1;
  }
  &__persons,
  &__articles {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  //- PERSON WRAPPER
  &__persons {
    width: 100%;
    min-height: 427px;
    z-index: 2;
    @media #{$maxW-960} {
      min-height: 300px;
    }
  }
  //- PERSON
  &__person {
    position: absolute;
    top: 0;
    opacity: 0;
    z-index: 1;
    transition: opacity .3s linear;
  }
  &__person--1 {
    width: 17.5%;
    top: .8%;
    left: 7%;
    z-index: 3;
  }
  &__person--2 {
    width: 14.8%;
    top: 1.3%;
    left: 19%;
    z-index: 1;
  }
  &__person--3 {
    width: 15.5%;
    top: 1.8%;
    left: 30%;
    z-index: 2;
  }
  &__person--4 {
    width: 15%;
    top: 8.5%;
    left: 40%;
    z-index: 3;
  }
  &__person--5 {
    width: 13.5%;
    top: .8%;
    left: 48.5%;
    z-index: 2;
  }
  &__person--6 {
    width: 12.5%;
    top: 6.7%;
    left: 59.5%;
    z-index: 1;
  }
  &__person--7 {
    width: 17%;
    top: 3.2%;
    left: 69%;
    z-index: 2;
  }
  &__person--8 {
    width: 16%;
    top: 6.7%;
    left: 81%;
    z-index: 1;
  }
  //- ARTICLES WRAPPER
  &__articles {
    width: 100%;
    margin-left: 30%;
    text-align: left;
    overflow: hidden;
    z-index: 2;
  }
  //- ARTICLE DETAIL
  &__article {
    width: 70%;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(-150%, 0, 0);
    &--detail {
      width: 100%;
      margin-top: 100px;
      position: static;
      transform: none;
      .btn {
        margin: 10px auto 40px;
        display: table;
      }
    }
    &--detail:first-child {
      margin-top: 0;
    }
    p {
      margin-top: 25px;
    }
  }
  //- ### ### ### PAGINATION
  &__pagHolder {
    width: 220px;
    max-width: 25%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-35%);
    z-index: 2;
    @media #{$maxW-750} {
      width: 100%;
      max-width: 100%;
      margin-top: 45px;
      position: static;
      transform: none !important;
    }
  }
  &__pag {
    position: relative;
    @media #{$maxW-460} {
      @include flex();
      justify-content: space-between;
    }
  }
  &__pagItem {
    @include size(100%, auto);
    @include clearfix();
    display: inline-block;
    border-top: 1px solid rgba(0, 0, 0, .15);
    border-radius: 0;
    background: none;
    line-height: 40px;
    text-align: left;
    opacity: 1;
    cursor: pointer;
    @media #{$maxW-750} {
      @include flexItem();
      width: 115px;
      margin: 0 10px;
      border: 0 !important;
    }
    &:last-child {
      border-bottom: 1px solid rgba(0, 0, 0, .15);
    }
    // arrow
    &:hover svg {
      fill: $white;
      opacity: 1;
    }
    &.active svg {
      fill: $white;
      opacity: 1;
    }
    svg {
      float: right;
      opacity: .15;
      transform: rotate(270deg) translateX(-50%);
      transition: opacity .3s linear, fill .3s linear;
      @media #{$maxW-750} {
        display: none;
      }
    }
  }
  &__pagTitle {
    @include robotoLight;
    display: inline-block;
    font-size: 18px;
    @media #{$maxW-750} {
      width: 100%;
      display: none;
      font-size: 16px;
      text-align: center;
      color: $black;
      text-decoration: underline;
    }
    &--mobile {
      margin-bottom: 30px;
      display: block;
      line-height: 1;
    }
  }
  &__pagRedirect {
    display: none;
    @media #{$maxW-750} {
      display: block;
    }
  }
  &__pagImg {
    width: 100%;
    max-height: 125px;
    min-height: 125px;
  }
}

//- ### ### ### PARALLAX
.parallax {
  .job {
    &__person {
      opacity: 1;
    }
    &__person--1 {
      transition-delay: .6s;
    }
    &__person--2 {
      transition-delay: .4s;
    }
    &__person--3 {
      transition-delay: .2s;
    }
    &__person--4 {
      transition-delay: 0s;
    }
    &__person--5 {
      transition-delay: .2s;
    }
    &__person--6 {
      transition-delay: .4s;
    }
    &__person--7 {
      transition-delay: .6s;
    }
    &__person--8 {
      transition-delay: .8s;
    }
    // ACTIVE IMAGE
    &__person.active {
      z-index: 99;
      transition-delay: 0s;
    }
    // INACTIVE IMAGES
    &__person.inactive {
      opacity: .5;
      transition-delay: 0s;
    }
    &__person.postParallax {
      transition-delay: 0s;
    }
  }
}

//- ### ### ### FOCUS
//- FOCUS PERSON
// first choose
// hide not chosen persons
.hidden.job__person {
  visibility: hidden;
}
.focus.job__person {
  left: 7%;
  opacity: 1 !important;
  transition: left .15s linear;
}
// FOCUS ARTICLE
.focus.job__article {
  transform: translate3d(0, 0, 0);
  transition: transform .3s linear;
  transition-delay: .15s;
}
//second+++ choose
.activated {
  // person
  .job__person {
    left: 7%;
    opacity: 0;
    transition: opacity .3s linear;
  }
  .focus.job__person {
    opacity: 1;
  }
  // article
  .focus.job__article {
    transition-delay: 0s;
  }
}
