.contact {
  padding-top: 60px;
  padding-bottom: 240px;
  text-align: center;
  @media #{$maxW-960} {
    padding-top: 35px;
  }
  &__helperGrid {
    min-height: 60vh;
  }
  &__wrapperGrid {
    width: 100%;
    min-height: 60vh;
  }
  &__grid {
    width: 100%;
    margin: -7.5px auto;
    padding: 55px 0 45px;
    @media #{$maxW-960} {
      padding-bottom: 100px;
    }
  }
  &__item {
    margin: 7.5px 0;
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
      opacity: 0;
      @media #{$maxW-960} {
        opacity: 1;
      }
    }
  }
  &__photo {
    @include size(120px, 104px);
    margin: auto;
    background-size: cover;
    &::after {
      border-top: 53px solid transparent;
      border-left: 30px solid $white;
      border-right: 30px solid $white;
    }
    &::before {
      border-bottom: 53px solid transparent;
      border-left: 30px solid $white;
      border-right: 30px solid $white;
    }
    &::after,
    &::before {
      width: 60px;
      content: '';
      display: block;
    }
  }
  &__desc {
    text-align: center;
  }
  &__name {
    @include robotoRegular;
  }
  &__job {
    color: $grey;
    font-size: 12px;
  }
}


// PARALLAX EFFECT */
.parallax {
  .contact__item {
    opacity: 1;
    transition: opacity .1s linear;
    &:nth-child(1) {
    }
    &:nth-child(2) {
      transition-delay: .1s;
    }
    &:nth-child(3) {
      transition-delay: .2s;
    }
    &:nth-child(4) {
      transition-delay: .3s;
    }
    &:nth-child(5) {
      transition-delay: .4s;
    }
    &:nth-child(6) {
      transition-delay: .5s;
    }
    &:nth-child(7) {
      transition-delay: .6s;
    }
    &:nth-child(8) {
      transition-delay: .7s;
    }
  }
}
