.navigation {
  @include robotoRegular;
  display: inline-block;
  position: fixed;
  top: 100px;
  right: 20px;
  overflow: hidden;
  z-index: 999;
  @media #{$maxW-960} {
    position: absolute;
    top: 20px;
  }
  display: block;
  @media #{$maxW-750} {
    width: 100%;
    top: 99px;
    right: auto;
  }
  &__list {
    display: inline-block;
    text-align: right;
    transform-origin: top;
    transition: height .3s linear, transform .3s linear, padding .3s linear;
    @media #{$maxW-960} {
      height: 0;
      padding: 0;
      display: block;
      border: 1px solid $skyBlue;
      border-top: 0;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      transform: scale3d(1, 0 ,1);
      background: linear-gradient(rgba(174, 219, 240, 1) 60%, rgba(125, 197, 231, 1));
      @media #{$maxW-750} {
        width: 290px;
        display: block;
        margin: auto;
        background: $white;
        text-align: center;
      }
    }
    // responsive active state
    &.active {
      @media #{$maxW-960} {
        height: 100%;
        padding: 12px;
        transform: scale3d(1, 1 ,1);
        display: block;
      }
    }
  }
  &__item {
    display: block;
    @media #{$maxW-960} {
      padding: 5px 0;
    }
  }
  &__link {
    padding-right: 7px;
    color: transparent;
    @media #{$maxW-960} {
      color: $blue;
    }
    &::after {
      @include size(15px, 15px);
      content: '';
      margin-left: 7px;
      display: inline-block;
      vertical-align: middle;
      border-radius: 100%;
      background-color: $black;
      opacity: .15;
      @media #{$maxW-960} {
        display: none;
      }
    }
    &:hover {
      color: $black;
      @media #{$maxW-960} {
        color: $blue;
      }
      &::after {
        opacity: .25;
      }
    }
    // active link
    &.active {
        color: $black;
      &::after {
        opacity: .35;
      }
    }
  }
}

// MENU BOX
.menu {
  @include robotoLight;
  height: 30px;
  max-width: 82px;
  margin-left: auto;
  padding: 0 10px;
  display: none;
  border: 1px solid $blue;
  border-radius: 5px;
  font-size: 14px;
  line-height: 30px;
  background: linear-gradient(rgba(174, 219, 240, 1) 60%, rgba(125, 197, 231, 1));
  text-align: right;
  cursor: pointer;
  transition: border-radius .3s linear, max-width .3s linear;
  transition-delay: .1s;
  @media #{$maxW-960} {
    display: block;
  }
  @media #{$maxW-750} {
    @include size(290px, 40px);
    max-width: 100%;
    padding: 0 15px;
    position: relative;
    margin: auto;
    border-radius: 0;
    background: $white;
    font-size: 18px;
    line-height: 40px;
    text-align: left;
  }
  &__burger {
    display: inline-block;
    vertical-align: baseline;
    @media #{$maxW-750} {
      float: right;
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
    }
  }
  &__line {
    @include size(12px, 2px);
    margin-bottom: 2px;
    margin-left: 12px;
    display: block;
    background-color: $skyBlue;
  }
  &__label {
    display: inline-block;
    vertical-align: top;
    color: $skyBlue;
  }
  // active state
  &.active {
    @media #{$maxW-960} {
      max-width: 200px;
      border-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      transition: 0;
      transition: max-width .3s linear;
    }
    @media #{$maxW-750} {
      max-width: 100%;
    }
  }
}
