.services {
  padding: 70px 0 85px;
  position: relative;
  text-align: center;
  color: $white;
  @media #{$maxW-960} {
    padding-top: 30px;
  }
  &__wrapper {
    min-height: 70vh;
    margin: 40px -38px -38px;
  }
  &__grid {
    width: 100%;
  }
  &__helperGrid {
    width: 100%;
    min-height: 70vh;
  }
  &__box {
    width: 275px;
    margin: 35px;
    opacity: 0;
    @media #{$maxW-750} {
      opacity: 1;
    }
  }
  // PARALLAX ANIMATION HOLDER
  &__anim {
    @include size(100%, 140px);
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
}

//- ### ### ### ### ### ### ### ###
//- INITIAL PARALLAX
//- ### ### ### ### ### ### ###
.parallax .services__box {
  opacity: 1;
  transition: opacity .3s linear;
  &:nth-child(2) {
    transition-delay: .1s;
  }
  &:nth-child(3) {
    transition-delay: .3s;
  }
  &:nth-child(4) {
    transition-delay: .5s;
  }
  &:nth-child(5) {
    transition-delay: .7s;
  }
  &:nth-child(6) {
    transition-delay: .9s;
  }
}

//- ### ### ### ### ### ### ### ###
//- ANIMACE
//- ### ### ### ### ### ### ###
.anim {
  max-width: none;
  max-height: none;
  position: absolute;
  z-index: 1;
  transition: transform .3s linear, top .2s ease-out, left .5s linear, width .5s linear, opacity .5s linear;
  top: 0;
  left: 0;
}
.ikona {
  width: 25%;
  top: -50%;
  left: 50%;
  transform: translate3d(-50%, -300%, 0);
  z-index: 1;
  @media #{$maxW-750} {
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

// ACTIVE GENERAL
.services__box:hover {
  .ikona {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    transition-delay: .3s;
  }
}

//- ### ### ### ANIMATION MODULES
//- ### VYVOJ - modul
@mixin vyvojDefault {
  .zarovky {
    width: 100%;
    top: -76px;
    left: -21px;
    z-index: 1;
  }
  .ikona {
    height: 75px;
  }
  .okno {
    width: 55%;
    z-index: 2;
    transform: translate3d(45px, 24px, 0);
  }
  .kluk {
    width: 16%;
    z-index: 3;
    transform: translate3d(15px, 5px, 0);
  }
  .holka {
    width: 14%;
    z-index: 3;
    transform: translate3d(182px, 40px, 0);
  }
  .zidle {
    width: 45px;
    transform: translate3d(110px, 70px, 0);
    z-index: 3;
  }
  .stul {
    width: 225px;
    width: 225px;
    height: 142px;
    background-image: url("../content/images/service/vyvoj/table.svg");
    background-size: contain;
    transform: translate3d(15px, 55px, 0);
    z-index: 4;
    // prevent hover blink
    &::after {
      content: '';
      background-image: url("../content/images/service/vyvoj/table-b.svg");
    }
  }
}
@mixin vyvojActive {
  .okno {
    opacity: 0;
  }
  .kluk {
    transform: translate3d(5px, 5px, 0);
  }
  .holka {
    transform: translate3d(215px, 40px, 0);
  }
  .zidle {
    opacity: 0;
  }
  .stul {
    width: 235px;
    transform: translate3d(15px, 60px, 0);
    animation: servicesTableBg .1s forwards;
    animation-delay: .2s;
  }
}

//- ### TESTING - modul
@mixin testingDefault {
  .bgleft {
    width: 55px;
    transform: translate3d(25px, 20px, 0);
    z-index: 1;
  }
  .bgright {
    width: 60px;
    transform: translate3d(175px, 30px, 0);
    z-index: 1;
  }
  .ikona {
    height: 88px;
  }
  .loga {
    width: 40px;
    transform: translate3d(210px, 115px, 0);
    z-index: 1;
  }
  .kluk {
    width: 50px;
    transform: translate3d(55px, 10px, 0);
    z-index: 2;
  }
  .holka {
    width: 43px;
    transform: translate3d(160px, 20px, 0);
    z-index: 2;
  }
  .lady {
    width: 85px;
    transform: translate3d(95px, -5px, 0);
    z-index: 3;
  }
}
@mixin testingActive {
  .bgleft {
    transform: translate3d(35px, 20px, 0);
    transition-delay: 1.3s;
  }
  .bgright {
    transform: translate3d(160px, 30px, 0);
    transition-delay: 1.3s;
  }
  .kluk {
    transform: translate3d(-100px, 10px, 0);
  }
  .holka {
    transform: translate3d(220px, 20px, 0);
  }
  .lady {
    transform: translate3d(20px, -5px, 0);
  }
}

//- ### VZDELAVANI - modul
@mixin vzdelavaniDefault {
  .bgleft {
    width: 100px;
    transform: translate3d(20px, 25px, 0);
    z-index: 1;
  }
  .bgright {
    width: 140px;
    transform: translate3d(140px, 30px, 0);
    z-index: 1;
  }
  .ikona {
    height: 70px;
  }
  .mister {
    width: 125px;
    transform: translate3d(65px, -20px, 0);
    z-index: 2;
  }
}
@mixin vzdelavaniActive {
  .bgleft {
    transform: translate3d(-20px, 25px, 0);
  }
  .bgright {
    transform: translate3d(180px, 30px, 0);
  }
  .mister {
    width: 70px;
    transform: translate3d(10px, 5px, 0);
  }
}

//- ### SERVIS - modul
@mixin servisDefault {
  .bg {
    width: 160px;
    transform: translate3d(65px, 25px, 0);
    z-index: 1;
  }
  .ikona {
    height: 85px;
    animation: none;
  }
  .ntb {
    width: 200px;
    height: 120px;
    background-image: url("../content/images/service/servis/ntb.svg");
    background-size: contain;
    background-repeat: no-repeat;
    transform: translate3d(30px, 15px, 0);
    z-index: 3;
    // prevent blink on hover
    &::after {
      content: '';
      background-image: url("../content/images/service/servis/ntb-b.svg");
    }
  }
}
@mixin servisActive {
  .ikona {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
    animation: spinMeRoundAround 1.5s forwards;
    animation-delay: .3s;
    transition-delay: .3s;
  }
  .ntb {
    width: 210px;
    height: 130px;
    transform: translate3d(-100px, 15px, 0);
    animation: servicesLaptopBg .1s forwards;
    animation-delay: .2s;
  }
}

//- ### BODYSHOP - modul
@mixin bodyshopDefault {
  .bg {
    width: 180px;
    transform: translate3d(45px, 25px, 0);
    z-index: 1;
  }
  .ikona {
    height: 60px;
  }
  .boyBlack {
    width: 60px;
    transform: translate3d(50px, 10px, 0);
    z-index: 2;
  }
  .boyRed {
    width: 50px;
    transform: translate3d(165px, 20px, 0);
    z-index: 2;
  }
  .boyBrown {
    width: 83px;
    transform: translate3d(80px, 5px, 0);
    z-index: 3;
  }
}
@mixin bodyshopActive {
  .boyBlack {
    width: 60px;
    transform: translate3d(-50px, 10px, 0);
    z-index: 2;
  }
  .boyRed {
    width: 50px;
    transform: translate3d(205px, 20px, 0);
    z-index: 2;
  }
  .boyBrown {
    width: 83px;
    transform: translate3d(-20px, 5px, 0);
    z-index: 3;
  }
}

//- ### PROVERENI - modul
@mixin provereniDefault {
  .bg {
    width: 180px;
    transform: translate3d(100px, 25px, 0);
    z-index: 1;
  }
  .lady {
    width: 109%;
    transform: translate3d(-21px, -7px, 0);
    z-index: 2;
  }
  .ikona {
    height: 58px;
    z-index: 3;
  }
  .pc {
    width: 100px;
    transform: translate3d(40px, 53px, 0);
    z-index: 3;
  }
}
@mixin provereniActive {
  .lady {
    transform: translate3d(85px, -7px, 0);
  }
  .pc {
    transform: translate3d(-20px, 53px, 0);
  }
}

//- ### ### ### VYVOJ
.services__anim--vyvoj {
  @include vyvojDefault;
  background-color: $vyvoj;
  @media #{$maxW-750} {
    @include vyvojActive;
  }
}
// ACTIVE - vyvoj
.services__box:hover .services__anim--vyvoj {
  @include vyvojActive;
}

//- ### ### ### TESTING
.services__anim--test {
  @include testingDefault;
  background-color: $test;
  @media #{$maxW-750} {
    @include testingActive;
  }
}
// ACTIVE - testing
.services__box:hover .services__anim--test {
  @include testingActive;
}

//- ### ### ### VZDELAVANI
.services__anim--vzdelavani {
  @include vzdelavaniDefault;
  background-color: $vzdelavani;
  @media #{$maxW-750} {
    @include vzdelavaniActive;
  }
}
// ACTIVE - vzdelavani
.services__box:hover .services__anim--vzdelavani {
  @include vzdelavaniActive;
}

//- ### ### ### SERVIS
.services__anim--servis {
  @include servisDefault;
  background-color: $servis;
  @media #{$maxW-750} {
    @include servisActive;
  }
}
// ACTIVE - servis
.services__box:hover .services__anim--servis {
  @include servisActive;
}
//- ### ### ### BODYYSHOP
.services__anim--bodyshop {
  @include bodyshopDefault;
  background-color: $bodyshop;
  @media #{$maxW-750} {
    @include bodyshopActive;
  }
}
// ACTIVE - bodyshop
.services__box:hover .services__anim--bodyshop {
  @include bodyshopActive;
}

//- ### ### ### PROVERENI
.services__anim--provereni {
  @include provereniDefault;
  background-color: $provereni;
  @media #{$maxW-750} {
    @include provereniActive;
  }
}
// ACTIVE - provereni
.services__box:hover .services__anim--provereni {
  @include provereniActive;
}
