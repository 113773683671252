@mixin makeScroller($bg, $hover) {
  border: 1px solid $bg;
  background-color: $bg;
  fill: $hover;
  &:hover {
    background-color: $hover;
    svg {
      fill: $bg;
    }
  }
}

.scroller {
  width: 100%;
  position: absolute;
  bottom: 10px;
  text-align: center;
  z-index: 10;
  @media #{$maxW-750} {
    display: none;
  }
  &::before {
    content: '';
    width: 100%;
    display: block;
    position: absolute;
    top: 50%;
    border-top: 1px solid rgba(0, 0, 0, .15);
    z-index: 1;
  }
  &__btn {
    @include size(50px, 50px);
    margin: auto;
    display: inline-block;
    position: relative;
    border-radius: 100%;
    line-height: 58px;
    transition: background-color .3s  linear, border-top .3s  linear;
    z-index: 2;
    @media #{$maxW-960} {
      @include size(35px, 35px);
      line-height: 35px;
    }
    &--white {
      @include makeScroller($white, $skyBlue);
    }
    &--blu {
      @include makeScroller($skyBlue, $white);
    }
  }
  svg {
    @media #{$maxW-960} {
      @include size(12px, 11px);
    }
    transition: fill .3s  linear;
  }
}
