.integromat {
  padding: 200px 0 130px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  @media #{$maxW-960} {
    padding: 100px 0;
  }
  @media #{$maxW-960} {
    padding: 30px 0 100px;
  }
  &__parallax {
    min-height: 60vh;
  }
  &__helperGrid {
    width: 100%;
  }
  &__item {
    height: 100%;
    position: relative;
    z-index: 2;
    @media #{$maxW-960} {
      padding-right: 10px;
    }
    @media #{$maxW-750} {
      padding-right: 0;
    }
    &--text {
      align-self: center;
      transform: translate3d(-300%, 0 ,0);
      @media #{$maxW-960} {
        transform: none !important;
      }
    }
    &--video {
      padding-left: 45px;
      position: relative;
      transform: rotate3d(1, 0 , 0, -90deg);
      transform-origin: bottom;
      @media #{$maxW-960} {
        padding: 0;
        transform: none !important;
      }
      &::after {
        @include size(20px, 20px);
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 20px -31px 197px 127px $black;
        z-index: 0;
        @media #{$maxW-960} {
          box-shadow: 0 0 200px 80px #000;
        }
        @media #{$maxW-750} {
          display: none;
        }
      }
      img {
        position: relative;
        z-index: 2;
        @media #{$maxW-750} {
          display: none;
        }
      }
    }
  }
  &__link {
    margin-top: 25px;
    white-space: nowrap;
    @media #{$maxW-1024} {
      white-space: normal;
    }
    @media #{$maxW-960} {
      text-align: center;
    }
    @media #{$maxW-750} {
      @include robotoBold;
      margin-top: 0;
      margin-bottom: 25px;
      color: $black;
      a {
        color: $black;
      }
    }
  }
  &__video,
  &__videoBg {
    @include size(85%, 64%);
    display: block;
    position: absolute;
    top: 5%;
    left: 12.2%;
    @media #{$maxW-960} {
      width: 92%;
      left: 4.2%;
    }
    @media #{$maxW-750} {
      @include size(400px, 220px);
      margin: 30px auto 0;
      position: static;
      border: 1px solid grey;
    }
    @media #{$maxW-460} {
      @include size(270px, 150px);
    }
  }
  &__video {
    background: url('../content/images/video.jpg') no-repeat center;
    background-size: cover;
    transform: scale3d(0, 0, 1);
    z-index: 4;
    @media #{$maxW-960} {
      transform: none;
    }
  }
  &__videoBg {
    display: none;
  }
  h2 {
    @media #{$maxW-960} {
      text-align: center;
    }
  }
  // SPINWHEELS
  .wheel {
    position: absolute;
    top: 0;
    opacity: 0;
    transition: opacity .3s linear;
    @media #{$maxW-960} {
      display: none;
    }
    &--1 {
      width: 9%;
      max-width: 175px;
      top: 11%;
      left: 45%;
      transition-delay: .7s;
      z-index: 3;
    }
    &--2 {
      width: 19%;
      max-width: 345px;
      top: 13%;
      right: 5%;
      transition-delay: .9s;
      z-index: 3;
    }
    &--3 {
      width: 15%;
      max-width: 265px;
      top: 55%;
      left: 50%;
      transition-delay: 1.1s;
      z-index: 1;
    }
    &--4 {
      width: 16%;
      max-width: 275px;
      top: 13%;
      left: 5%;
      transition-delay: 1.2s;
      z-index: 3;
    }
  }
}

// PARALLAX EFFECT */
.parallax {
  .integromat {
    &__item--text {
      transform: translate3d(0, 0 ,0);
      transition: transform .3s ease-out;
    }
    &__item--video {
      transform: rotate3d(1, 0 , 0, 0deg);
      transition: transform .3s linear;
      transition-delay: .3s;
    }
    &__video {
      transform: scale3d(1, 1, 1);
      transition: transform .3s ease-out;
      transition-delay: .6s;
    }
  }
  .wheel {
    opacity: 1;
  }
}
