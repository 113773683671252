// WIDTH / HEIGHT
@mixin size($width, $height) {
  width: $width;
  height: $height;
}

// MAX WIDTH / HEIGHT
@mixin maxSize($width, $height) {
  max-width: $width;
  max-height: $height;
}

// CLEARFIX
@mixin clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

// FLEXBOX
@mixin flex($direction: row, $wrap: wrap, $justify: center) {
  display: flex;
  flex-direction: $direction;
  flex-wrap: $wrap;
  justify-content: $justify;
}

@mixin flexItem($basis: auto, $shrink: 1, $grow: 0) {
  flex-basis: $basis;
  flex-shrink: $shrink;
  flex-grow: $grow;
}

// COLUMNS
@mixin columns($type) {
  $columns: 12;
  $column-width: 8.3333%;
  @for $i from 1 through $columns {
    .col#{$type}-#{$i} {
      @if $i == $columns {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 100%;
        width: 100%;
        max-width: 100%;
      } @else {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: $column-width * $i;
        max-width: $column-width * $i;
      }
    }
  }
}

// ROUND NUMBER
@function pow($x, $n) {
  $ret: 1;
  @if $n >= 0 {
    @for $i from 1 through $n {
      $ret: $ret * $x;
    }
  } @else {
    @for $i from $n to 0 {
      $ret: $ret / $x;
    }
  }
  @return $ret;
}

@function precision-round($float, $digits) {
  $sass-precision: 5;
  $pow: pow(10, $digits);
  @return round($float * $pow) / $pow;
}// end round

// CALC PX TO REM
@function toRem($number) {
  $converted: $number / $defaultFontSize;
  $rounded: precision-round($converted, 3);
  @return $rounded+rem;
}


// DROPDOWN ARROW MAKER
@mixin dropdownArrow($clr, $orientation, $size: 6px) {
  border-top: $size solid transparent;
  border-bottom: $size solid transparent;
  border-left: $size solid transparent;
  border-right: $size solid transparent;
  @if $orientation == top {
    top: calc(50% - #{$size * 1.5 });
    border-bottom: $size solid $clr;
  } @else if $orientation == bottom {
    top: calc(50% - #{$size / 1.5 });
    border-top: $size solid $clr;
  } @else if $orientation == right {
    border-right: $size solid $clr;
  } @else {
    border-left: $size solid $clr;
  }
}
