.services {
  &__slider {
    height: 100%;
  }
  &__slide {
    min-height: 100vh;
    padding: 130px 90px 170px;
    display: flex;
    justify-content: center;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    @media #{$maxW-960} {
      margin-bottom: 70px;
      padding: 100px 90px 70px;
      flex-wrap: wrap;
      @media #{$maxW-750} {
        margin-bottom: 40px;
        padding: 120px 10px 40px;
      }
      @media #{$maxW-460} {
        padding: 215px 10px 40px;
      }
    }
  }
  // ARROWS
  &__arrows {
    display: none;
    @media #{$maxW-750} {
      top: 25%;
    }
    @media #{$maxW-460} {
      top: 35%;
    }
    &--left {
      left: 10px;
    }
    &--right {
      right: 10px;
    }
    &.active {
      display: block;
    }
  }
  // PAGINATION
  &__pagination {
    position: relative;
    transform: translate3d(120%, 0, 0);
    @media #{$maxW-960} {
      transform: none;
    }
    &Wrapper {
      width: calc(100% - 180px);
      min-width: 960px;
      height: 200px;
      padding: 0 90px;
      position: absolute;
      bottom: 53px;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: 0px -25px 34px -39px $black;
      overflow: hidden;
      z-index: 2;
      @media #{$maxW-960} {
        width: calc(100% - 90px);
        min-width: 100%;
        padding: 0 45px;
      }
      @media #{$maxW-750} {
        width: 100%;
        height: auto;
        max-width: 100%;
        min-width: auto;
        padding: 0 0 60px;
        position: static;
        box-shadow: none;
        transform: none;
      }
      // active pagination state
      &.active {
        height: 106px;
        bottom: 50px;
        transition: height .4s linear, bottom .4s linear;
        .services__pagination {
          transform: scale3d(.55, .55, 1) translate(45%, -45%);
          transition: transform .4s linear;
        }
        .swiper-pagination-bullet {
          flex-grow: 0;
          border-radius: 0;
          &:hover {
            transform: scale3d(1.1, 1.1, 1);
          }
        }
        .swiper-pagination-bullet-active {
          background-image: url('../content/icons/polygon.png');
          background-position: center;
          background-repeat: no-repeat;
          background-size: 90%;
          background-position: 6px 10.5px;
          &:hover {
            transform: none;
          }
        }
        .swiper-pagination-bullet-desc {
          display: none;
          @media #{$maxW-960} {
            font-size: 18px !important;
          }
          @media #{$maxW-750} {
            br {
              display: none;
            }
          }
        }
        & ~ .services__wrapper .services__contentItem {
          @media #{$maxW-750} {
            height: auto;
            min-height: 800px;
          }

        }
      } // end active
    }
    .swiper-pagination-bullet {
      @include size(auto, auto);
      padding: 0 16px;
      position: relative;
      background: none;
      flex-grow: 1;
      opacity: 1;
      @media #{$maxW-750} {
        padding: 30px 20px;
      }
      &:hover {
        .swiper-pagination-bullet-desc {
          text-decoration: underline;
        }
      }
      .swiper-pagination-bullet-desc {
        width: 100%;
        text-align: center;
        position: absolute;
        left: 0;
        font-size: 14px;
        @media #{$maxW-750} {
          text-decoration: underline;
        }
      }
      img {
        @include size(93px, auto);
        padding: 20px 0 10px;
        @media #{$maxW-960} {
          width: 75px;
          padding: 6px 0;
        }
      }
    }
  }
  &__slideDesc {
    padding-top: 60px;
    @media #{$maxW-960} {
      padding-top: 0;
    }
    p {
      margin-top: 25px;
    }
  }//- end pagination
  &__slideLeft,
  &__slideRight {
    max-width: 525px;
    max-height: 500px;
  }
  &__slideLeft {
    img {
      max-height: 560px;
      @media #{$maxW-960} {
        max-width: 540px;
        position: absolute;
        left: 0;
        z-index: 0;
      }
      @media #{$maxW-750} {
        max-width: 999px;
        max-height: 270px;
        position: static;
      }
    }
  }
  &__slideRight {
    text-align: left;
    z-index: 2;
  }
  // select transform
  @include select(960px);
}
